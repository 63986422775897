.lmj-plant-item-cover {
    height: 250px;
    width: 250px;
    border-radius: 20px;
    object-fit: cover;
}

.lmj-plant-item {
    margin: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-transform: capitalize;
    position: relative;
}

.lmj-plant-item-price {
    position: absolute;
    border-radius: 20px 20px 20px 0;
    background-color: #31b472;
    color: white;
    padding: 15px;
    right: 0;
    top: 0;
    font-weight: 500;
}