.lmj-shopping-list {
	width: 100%;
}

.lmj-plant-list {
	list-style-type: none;
	padding: 32px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.lmj-plant-list button {
	margin-left: 50px;
}